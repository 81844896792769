import { DOM, IS_TOUCH, CLASSES } from '../helpers/consts';

const initScrollPanel = () => {
  const $panel = $('.js-scroll-panel');

  if(!$panel[0] || !IS_TOUCH) return;

  const $closeBtn = $panel.find('.js-scroll-panel-close');


  const scrollHandle = () => {
    const scrollPos = window.pageYOffset;
    if(scrollPos <= 4){
      $panel.removeClass(CLASSES.active);
    }else{
      $panel.addClass(CLASSES.active);
    }
  }

  const closeHandle = () => {
    $panel.removeClass(CLASSES.active);
    DOM.$win.off("scroll", scrollHandle);
  };
  
  DOM.$win.scroll(scrollHandle);
  $closeBtn.on('click', closeHandle);

};
export default initScrollPanel;